import React from 'react'
import PropTypes from 'prop-types'
import {
  NotFoundLabel,
  NotFoundLink,
  NotFoundLinkIcon,
  NotFoundLinkText,
  NotFoundMain,
  NotFoundText,
} from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import PageTransitionLink from '../PageTransitionLink'
import IconArrowheadLeft from '../_svgs/IconArrowheadLeft'
import { animation } from '../../styles/vars/animation.style'
import Routes from '../../routes'

const NotFound = ({ title }) => {
  const entryDelay = animation.pageExitDuration

  return (
    <NotFoundMain>
      <NotFoundText>
        <NotFoundLabel aria-hidden>
          <AnimateSlideIn delay={entryDelay} direction="right">
            404
          </AnimateSlideIn>
        </NotFoundLabel>

        <AnimateSplitText delay={entryDelay} heading>
          Page not found
        </AnimateSplitText>
      </NotFoundText>

      <NotFoundLink>
        <AnimateSlideIn direction="left" delay={entryDelay}>
          <PageTransitionLink to={Routes.home}>
            <NotFoundLinkIcon>
              <IconArrowheadLeft responsive />
            </NotFoundLinkIcon>
            <NotFoundLinkText>Back to Home</NotFoundLinkText>
          </PageTransitionLink>
        </AnimateSlideIn>
      </NotFoundLink>
    </NotFoundMain>
  )
}

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
}

export default NotFound
