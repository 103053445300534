import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const NotFoundMain = styled.div`
  overflow: hidden;

  ${mq.belowHorizontal} {
    padding: 11.1rem 3.2rem 2rem;
  }

  ${mq.horizontal} {
    padding-left: ${horizontalScale(125)};
    padding-right: ${horizontalScale(65)};
    padding-bottom: ${horizontalScale(40)};
  }
`

export const NotFoundText = styled.h1`
  position: relative;
  max-width: 1.26em;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 22.3rem;
  line-height: 0.84;
  text-transform: uppercase;

  ${mq.mobileL} {
    max-width: none;
  }

  ${mq.horizontal} {
    max-width: 2em;
    font-size: ${horizontalScale(300)};
    letter-spacing: -0.03em;
  }
`

export const NotFoundLabel = styled.span`
  position: absolute;
  top: 0;
  left: ${horizontalScale(-38)};
  transform-origin: 0% 0%;
  transform: rotate(90deg) translateY(-100%);
  display: none;
  font-family: ${font.primary};
  font-size: ${horizontalScale(12)};
  font-weight: ${font.weight.regular};
  line-height: 1;
  letter-spacing: 0;
  word-break: normal;

  ${mq.horizontal} {
    display: block;
  }
`

export const NotFoundLink = styled.div`
  ${mq.horizontal} {
    display: flex;
  }

  ${PageTransitionLinkMain} {
    display: flex;
    align-items: center;
  }
`

export const NotFoundLinkIcon = styled.span`
  ${mq.horizontal} {
    width: ${horizontalScale(30)};
    margin-right: ${horizontalScale(12)};
  }
`

export const NotFoundLinkText = styled.span`
  ${mq.horizontal} {
    font-size: ${horizontalScale(32)};
  }
`
