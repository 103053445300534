import React from 'react'
import SEO from '../components/SEO'
import { ScrollWrapper } from '../components/Scroll/index.style'
import ScrollSection from '../components/ScrollSection'
import NotFound from '../components/404'

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404" description="Page not found" />
      <ScrollWrapper>
        <ScrollSection width={920}>
          <NotFound />
        </ScrollSection>
      </ScrollWrapper>
    </>
  )
}

export default NotFoundPage
